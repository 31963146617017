.left{
    float: left;
}

.right{
    float: right;
}
.message-container{
    height: 100vh;
  

    padding: 1%;
}

.conversation-container{
    position: relative;
    width:100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    background-color: white;
    border-radius: 1.5rem;
    /* filter: alpha(opacity=50); */
    /*background-repeat: repeat-x;*/
    background-size: contain    ;
    box-shadow: 1px 1px 10px 2px rgba(0,0,0,0.25);
}



.conversation-container>.send-box{
    /*position: absolute;*/
    /*position: absolute;*/
    /*bottom:0px;*/
    width: 100%;
    background-color:#f8fbfd;
    box-shadow: 0 20px 24px 0 rgba(10, 31, 68, 0.15);
    padding: 3px;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    /*height: 20%;*/
    
}

.send-box>.send-tools{
    border-top:1px solid #53d1c1;
}

.img-circle{
    border-radius: 50%;
    height: calc(1.5em + 0.75rem + 14px);
}

.round{
    border-radius: 50%;
    min-width: 40px;
}

.message-input{
    border-radius: 20px;
    max-height: 100px;
    display: inline;
    width: 98%;
    border-color: #bbb;
    position: relative;
    border-width: 1px !important;
    padding: 10px !important;
    border-style: double;

}

.message-input:focus{
    outline: none;
    outline-width: none;
    box-shadow: none;
    padding-right: 70px !important;

}

.conversation-title-container{
   background: #4a90e2;
   border-top-right-radius:1.5rem;
   border-top-left-radius: 1.5rem;
    height: 60px;
}

.conversation-title-container .title{
    font-size: 25px;
    position: relative;
    top:4px;
}

.hidden{
    visibility: none;
    position: absolute;
}

.slider {
	overflow-y: hidden;
	max-height: 500px; /* approximate max height */

	transition-property: all;
	transition-duration: .5s;
	transition-timing-function: cubic-bezier(0, 0.3, 0.5, 1.5);
}

.slider.closed {
	max-height: 0;
}

.slider.closed {
	max-height: 0;
}

@media only screen and (max-width: 768px) {
    .message-container{
       padding: 0px;
    }
    .conversation-container{
        border-radius: 0px;
    }

    .conversation-title-container{
        border-top-right-radius:0px !important;
        border-top-left-radius: 0px !important;
     }
  }
