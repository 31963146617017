.App {
  text-align: center;
  
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered{
  margin-left: auto;
  margin-right: auto;
}

.hidden{
  visibility: hidden;
  position: absolute;
}

::-webkit-scrollbar { 
    width: 4px;
}
::-webkit-scrollbar:hover {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scroll{
    overflow-y:scroll
  }

  button:focus{
    box-shadow: none !important;
    outline: none !important;
  }

  .dropdown-menu{
    /* background-color: transparent !important; */
    /* border:none !important; */
  }

.dropdown-menu>a {
  background: white;
    color:#333 !important;
    font-weight: 500;
    letter-spacing: 1px;

}



.dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown.show .dropdown-menu{ /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
  max-height: 90vh;
  opacity: 1;
}

.dropdown-header{
  padding: 5px !important;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 16px !important;
}

.dropdown-item{
  padding-left: 35px !important;
  font-size: 14px !important;


}

.dropdown-header i{
  width: 20px;
  font-size: 16px;
}
/*.navbar-drop-icon{*/
/*  font-size: 12px;*/
/*  width: 16px;*/
/*}*/
.list {
  height: calc(100vh - 210px)
  }

.h-100{
  height:100vh;
}

.flex-flow-column{
  display: flex;
  flex-flow: column;
}

.modal-content{
  border-radius: 1.7rem !important;
  box-shadow: 0 20px 24px 0 rgba(10, 31, 68, 0.15);
  border: none !important;

}

.modal-header{
  color: white;
  background: #4a90e2;
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;

}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 90vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

/*.btn-secondary{*/
/*  background-color: #999 !important;*/
/*  border-color: #999 !important;*/
/*}*/

a:hover{
  text-decoration: none !important;
}

.btn-primary{
  background-color: #4a90e2 !important;
  border-color: #4a90e2 !important;
}

.bg-primary{
  background-color: #4a90e2 !important;
}

.custom-shadow{
  box-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.25) !important;
}

.cursor-pointer{
  cursor: pointer;
}